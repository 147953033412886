import React from 'react'
import logo from './logo.svg'
import './App.css'
import watch from './1.png'
import notify from './2.png'
import { useWindowSize } from './useWindowSize'

function App() {
  const size = useWindowSize()

  const value = <T extends any>(
    size: ReturnType<typeof useWindowSize>,
    desktopValue: T,
    mobileValue: T
  ) => (size.width > 800 ? desktopValue : mobileValue)

  const styles: { [key: string]: React.CSSProperties } = {
    screenContainer: {
      paddingLeft: 8,
      paddingRight: 8,
      alignItems: 'center',
      display: 'flex',
      flexDirection: value(size, 'row', 'column'),
    },
    stepText: {
      marginTop: 0,
    },
    tryItOut: {
      fontSize: 19,
      marginTop: 28,
      marginLeft: 8,
      alignSelf: 'center',
      // fontWeight: 'bold',
      // backgroundColor: 'purple',
      // paddingLeft: 12,
      // paddingRight: 12,
      // paddingTop: 16,
      // paddingBottom: 16,
      // borderRadius: 12,
      // border: 'none',
      // color: 'white',
    },
  }

  return (
    <div className="App">
      <div
        style={{
          minHeight: '100vh',
          marginTop: 0,
          paddingTop: 1,
          paddingLeft: 32,
          paddingRight: 32,
          paddingBottom: 36,
        }}
      >
        <h1 style={{ color: 'white' }}>
          CommunautoWatcher <sup style={{ fontSize: 12 }}>beta</sup>
        </h1>

        <div
          style={{
            backgroundColor: undefined,
            display: 'flex',
            flexDirection: value(size, 'row', 'column'),
            justifyContent: 'center',
          }}
        >
          <div style={styles.screenContainer}>
            <img
              src={watch}
              style={{ height: '70vh' }}
              alt="pick an area on the map to watch"
            />
            <p style={styles.stepText}>
              1. Pick an area to watch for{' '}
              <a href="https://communauto.com/?lang=en">Communauto</a> cars.
            </p>
          </div>
          <div style={styles.screenContainer}>
            <img src={notify} style={{ height: '70vh' }} alt="get notified" />
            <p style={styles.stepText}>
              2. Get notified when a car becomes available.
            </p>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <a href="mailto:support@communautowatcher.com?subject=CommunautoWatcher%20Beta%20Access&body=Hello%2C%20I'd%20like%20to%20request%20access%20to%20the%20CommunautoWatcher%20beta%20program%20please.%20">
            <button style={styles.tryItOut}>Request access</button>
          </a>

          <div style={{ marginTop: 25 }}>
            <p>
              CommunautoWatcher is an independent project aimed at making car
              sharing in Canada more accessible, and is in no way affiliated
              with <a href="https://communauto.com/?lang=en">Communauto</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
